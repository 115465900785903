import "./modal.scss";
import { RiCloseLine } from "react-icons/ri";
import { createPortal } from "react-dom";

const Modal = ({ show, close, children }) => {
  const confirmClose = () => {
    if (window.confirm("Are you sure you want to close the modal?")) {
      close(); // Close the modal if the user confirms
    }
  };
  return createPortal(
    <>
      <div
        className={`modalContainer ${show ? "show" : ""} `}
        onClick={confirmClose}
      >
         <button className="closeBtn" onClick={confirmClose}>
            <RiCloseLine style={{ marginBottom: "-3px" }} /> 
          </button>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
         <main className="modal_content">{children}</main>
            </div>
      </div>
    </>,
    document.getElementById("modal")
  );
};

export default Modal;
